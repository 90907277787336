import React from 'react';

import Layout from 'components/Layout';

const PlacesPage = () => (
    <Layout>
        <div className="page-content">
            <h1>Places I've Been</h1>
            <h2>States</h2>
            <ul>
                <li>
                    New Jersey - Place of Residence (Blackwood, Hoboken, Jersey
                    City)
                </li>
                <li>Pennsylvania - Eagles Games, concerts, regattas, etc.</li>
                <li>
                    New York - Visits for Broadway shows; Skiing (Lake Placid,
                    2005); I work here
                </li>
                <li>Delaware - Tubing; Tatnall @ccelerated (2012)</li>
                <li>
                    Massachusetts - Boston (HotC, 2004; 2009, 2010, 2011 BU
                    Parents Weekend; 2016 Weekend)
                </li>
                <li>Connecticut - Choate @ccelerated</li>
                <li>
                    Maine - Sugarloaf Ski Resort (Stevens Trip January 2010)
                </li>
                <li>
                    Maryland - Aquarium; Silverpoint Retreats (2008, 2009, 2011)
                </li>
                <li>
                    Virginia - I feel like I must have been to Virginia for
                    something other than driving through to go to Bonnaroo
                </li>
                <li>
                    North Carolina - Visiting Sada (Trips in 2007, 2008, 2010?)
                </li>
                <li>South Carolina - Carrowinds (Sada)</li>
                <li>
                    Florida - Disney (1992, 1996, 2000, 2004, 2006, 2015);
                    Visiting Stefanie
                </li>
                <li>
                    Ohio - Cleveland for CWRU, Rock & Roll HOF (2005? 2004?);
                    Visiting Addie
                </li>
                <li>Tennessee - Bonnaroo 2009, 2010</li>
                <li>
                    Texas - Visiting Robertson family (2011-2012); GSES
                    @ccelerated; Eagles @ Cowboys, 2017; Trips in 2018, 2019
                </li>
                <li>Oklahoma - Maroon 5, NYE 2011</li>
                <li>Colorado - Breckenridge / Vail? (2002)</li>
                <li>
                    Utah - Skiing (Alta, Snowbird, Park City, Solitude - 1997?)
                </li>
                <li>Nevada - Lake Tahoe</li>
                <li>
                    California - Visiting Courtney (2005, 2006); Lake Tahoe
                    (2009); Silverpoint Retreats (2010, 2012); San Francisco
                    Vacation 2017
                </li>
                <li>
                    Oregon - Visiting TLO (2011); Nike in April and July 2016
                    and August 2017
                </li>
                <li>
                    I've totally been to Washington, DC, too. I forget when.
                </li>
            </ul>

            <h2>Countries</h2>
            <ul>
                <li>USA - Place of residence</li>
                <li>Bermuda - Family Vacation: _________________ (1999?)</li>
                <li>Nassau, Bahamas - Family Vacation: The Atlantis (2004)</li>
                <li>
                    Playa del Carmen, Mexico - Family Vacation: The Royal (2010)
                </li>
                <li>Guelph / Toronto, Ontario, Canada - Visiting Cassandra</li>
                <li>
                    Florence / Rome / Venice, Italy - Family Vacation (2012)
                </li>
            </ul>
        </div>
    </Layout>
);

export default PlacesPage;
